import React, {createContext, useCallback, useContext, useEffect, useReducer} from 'react'
import { useHistory } from 'react-router-dom'
import {BMSContext} from './BMSContext'
import {
    initialState, jobReducer,
    JOBS_ADD, JOBS_SELECT, JOBS_REPLACE, JOBS_SET, JOB_MESSAGES_SET, JOBS_REMOVE,
} from '../reducers/JobReducer'

const sort = (arr, prop, methods) => {
    try {
        function key(x) {
            try {
                if (methods.case_sensitive === false)
                    if (typeof prop === typeof "") return x[prop].toLowerCase();
            } catch (e) {}
            if (typeof prop === typeof "") return x[prop];
            return x;
        }
        if (prop instanceof Object) methods = prop;
        let swipe = 1;
        try {
            swipe = methods.reverse === true ? -1 : 1;
        } catch (e) {}
        arr.sort((a, b) => {
            if (key(a) < key(b)) return -1 * swipe;
            if (key(b) < key(a)) return      swipe;
            return 0;
        });
    } catch (err) {
        console.log('ERROR: sort:', err.name);
    }
    return arr;
}

export const JobContext = createContext()

const JobContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(jobReducer, initialState)
    const history = useHistory()
    const bms = useContext(BMSContext)

    const ensureAccounts = (jobs) => {
        return jobs?.map( (j) => ({
            ...j,
            accounts: (j.accounts ? j.accounts : [])
        }) )
    }
    const loadJobs = useCallback(() => {
        return bms.apiGet(bms.apiMap.jobs)
            .then( (json) => {
                if( json.data && json.data.jobs ) { return json.data.jobs }

                return []
            })
    }, [bms])
    const refreshJob = (id) => {
        getJob(id).then((json) => {
            //console.log('refreshJob: json:', json)
            if(json.error === '') {
                if( json.data.jobs.length > 0 ) {
                    dispatch({type: JOBS_REPLACE, job: json.data.jobs[0]})
                }
            } else {
                console.log('refreshJob: ERROR:', json.error)
            }

            return json
        })
    }
    const refreshJobs = () => {
        return loadJobs().then((jobs) => {
            const jids = jobs.map(j => j.id)
            const sids = state.jobs.map(j => j.id)

            // changed jobs
            const intersectionsIds = jids.filter(id => sids.includes(id))
            const compareJobs = intersectionsIds.map(id => jobs.filter(j => j.id === id)[0])
            const changedJobs   = compareJobs.filter(c => {
                const sjob = state.jobs.filter(sj => sj.id === c.id)
                if (c.status !== sjob.status) { return true }
                return false
            })

            changedJobs.forEach((cj) => dispatch({type: JOBS_REPLACE, job: cj}))

            // new jobs
            const newJobs = sids.filter(id => !jids.includes(id))
            newJobs.forEach((n) => dispatch({type: JOBS_ADD, job: n}))

            // removed jobs
            const removedJobs = jids.filter(id => !sids.includes(id))
            removedJobs.forEach((r) => dispatch({type: JOBS_REMOVE, id: r}))
        })
    }
    const selectJob = (job) => {
        if(!job) {
            dispatch({type: JOBS_SELECT, job: null})
            return
        }
        return bms.apiGet(`${bms.apiMap.jobs}/${job.id}`)
            .then( (json) => {
                if( json.error === '' ) {
                    if(json.data.jobs.length > 0) {
                        dispatch({type: JOBS_SELECT, job: json.data.jobs[0] })
                    }
                } else {
                    dispatch({type: JOBS_SELECT, job})
                }
            })
    }
    const setJobMessages = (id, messages) => { dispatch({ type: JOB_MESSAGES_SET, id, messages }) }
    const scheduleJob = (id) => {
        return bms.apiGet(`${bms.apiMap.jobs}/schedule/${id}`)
            .then( (json) => {
                //console.log('scheduleJob:', json)

                if( json.error === '' ) {
                    dispatch({ type: JOBS_REPLACE, job: json.data.job })
                    return json
                }

                console.log('scheduleJob: ERROR:', json.error)
                return { ...json, data: { job: {} }, }
                })
    }
    const startJob = (id) => {
        return bms.apiGet(`${bms.apiMap.jobs}/start/${id}`)
            .then( (json) => {
                console.log('startJob:', json)

                if( json.error === '' ) {
                    dispatch({ type: JOBS_REPLACE, job: json.data.job })
                    return json
                }

                console.log('startJob: ERROR:', json.error)
                return { ...json, data: { job: {} }, }
            })
    }

    const getJob  = (job) => {
        //console.log('getJob:', job)
        if (job)
            return bms.apiGet(`${bms.apiMap.jobs}/${job}`)
                .then( (json) => { return json })
    }
    const putJob  = (job) => {
        //console.log('putJob:', job)
        return bms.apiPut(`${bms.apiMap.jobs}/${job.id}`, JSON.stringify(job))
            .then( (json) => {
                //console.log('putJob: json', json)
                if( !json.error || json.error === "" ) {
                    dispatch({type: JOBS_REPLACE, job: json.data.job})
                }

                return json
            })
    }
    const postJob = (job) => {
        //console.log('postJob:', job)
        return bms.apiPost(bms.apiMap.jobs, JSON.stringify(job))
            .then( (json) => {
                //console.log('postJob: json', json)
                if( !json.error || json.error === "" ) {
                    dispatch({type: JOBS_ADD, job: json.data.job })
                }
                return json
            })
    }

    useEffect(() => {
        loadJobs().then((jobs) => { dispatch({type: JOBS_SET, jobs: ensureAccounts(jobs) }) })
            .catch( err => {
                if( err.response ) {
                    console.log('JobContextProvider.mount: ERROR fetch jobs\n', err.response.status, err.response.message)
                } else {
                    console.log('JobContextProvider.mount: ERROR fetch jobs\n', err.message)
                }
            } )
    }, [loadJobs, history])

    // Manipulação de contexto e reducers
    state.dispatch       = dispatch
    state.refreshJob     = refreshJob
    state.refreshJobs    = refreshJobs
    state.scheduleJob    = scheduleJob
    state.selectJob      = selectJob
    state.setJobMessages = setJobMessages
    state.sort           = sort
    state.startJob       = startJob

    // Chamadas a API
    state.postJob  = postJob
    state.putJob   = putJob
    state.getJob   = getJob

    return (
        <JobContext.Provider value={state}>
            {children}
        </JobContext.Provider>
    )

}

export default JobContextProvider

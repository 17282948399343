import Container from 'rsuite/lib/Container'
import Header from 'rsuite/lib/Header'
import Navbar from 'rsuite/lib/Navbar'
import Nav from 'rsuite/lib/Nav'
import Content from 'rsuite/lib/Content'
import React, {lazy, Suspense, useContext, useEffect} from 'react'
import {HashRouter as Router, Route, Switch, useHistory} from 'react-router-dom'
import {CHANGE_TAB} from '../../reducers/BMSReducer'
import {BMSContext} from '../../contexts/BMSContext'
import BulkLogoColor from '../../components/BulkLogoColor'
import UserContextProvider from '../../contexts/UserContext'
import JobContextProvider from '../../contexts/JobContext'

const DashboardPage = () => {
    const history = useHistory();

    const bms = useContext(BMSContext)
    //console.log('Dashboard:', bms)

    const mapaRotas = {
        1: {text: 'Home',     path:'/',      perm: 'user'},
        2: {text: 'Usuários', path:'/users', perm: 'admin'},
        3: {text: 'Sobre',    path:'/about', perm: 'user'},
    }
    const onSelect = (x) => {
        return bms.dispatch({type: CHANGE_TAB, activeTabKey: x})
    }
    useEffect(() => {
        if( history.location.pathname !== mapaRotas[bms.activeTabKey].path )
            history.push(mapaRotas[bms.activeTabKey].path)
    }, [bms.activeTabKey, mapaRotas, history])
    useEffect(() => {
        if( ! bms.isLoading && ! bms.isLoggedIn )
            if( history.location.pathname !== "/login" )
                history.push("/login")
    }, [bms.isLoading, bms.isLoggedIn, history])

    //const svgStyle = { width: '100px' }

    const About = lazy(() => import('../about/About') )
    const Jobs  = lazy(() => import('../jobs/jobs') )
    const JobsProvider = () => (<JobContextProvider><Jobs /></JobContextProvider>)
    const Users = lazy(() => import('../admin_users/crud_users') )
    const UserProvider = () => (<UserContextProvider><Users /></UserContextProvider>)

    const tabs    = bms.session.role === 'admin' ? Object.entries(mapaRotas) :
                                                   Object.entries(mapaRotas).filter(m => m[1].perm === 'user')
    //console.log('tabs: ', tabs)
    return (
        <div className="show-container">
            <Container>
                <Header>
                    <Navbar appearance={"inverse"}>
                        <Navbar.Header>
                            <a href={"/"} className="navbar-brand logo"><BulkLogoColor scale={0.06}/></a>
                        </Navbar.Header>
                        <Navbar.Body>
                            <Nav activeKey={bms.activeTabKey} onSelect={onSelect}>
                                {
                                    tabs.map(t => {
                                        return (
                                            <Nav.Item key={t[0]} eventKey={t[0]}>{t[1].text}</Nav.Item>
                                        )
                                    })
                                }
                            </Nav>
                        </Navbar.Body>
                    </Navbar>
                </Header>
                <Content>
                    <Suspense fallback={<div>Carregando...</div>}>
                        <Router>
                            <Switch>
                                <Route exact path={["/bms/#/", "/#/", "/"]}
                                       component={JobsProvider} history={history} />
                                <Route exact path={["/bms/#/users", "/#/users", "/users"]}
                                       component={UserProvider} history={history}  />
                                <Route exact path={["/bms/#/about", "/#/about", "/about"]}
                                       component={About} history={history}  />
                            </Switch>
                        </Router>
                    </Suspense>
                </Content>
            </Container>
        </div>
    )
}

export default DashboardPage
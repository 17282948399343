class ApiError extends Error {
    //constructor(message) {
    //    super(message)
    //    this.name = "ApiError"
    //}
    constructor(message, response) {
        super(message)
        this.name = "ApiError"
        this.response = response
    }
}

export default ApiError

import React, {createContext, useCallback, useContext, useEffect, useReducer} from 'react'
import { useHistory } from 'react-router-dom'
import {BMSContext} from './BMSContext'
import {userReducer, USER_SET, USER_ADD} from '../reducers/UserReducer'

export const UserContext = createContext()

const UserContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(userReducer, {users: []})
    const history = useHistory()
    const bms = useContext(BMSContext)

    //
    // TODO: providências quando resposta for 401
    const loadUsers = useCallback(() => {
        return bms.apiGet(bms.apiMap.users)
            .then( (json) => {
                if( json.data && json.data.users )
                    return json.data.users
                else
                    return []
            })
    }, [bms])
    const postUser = (user) => {
        //console.log('postUser:', user)
        return bms.apiPost(bms.apiMap.users, JSON.stringify(user))
            .then( (json) => {
                //console.log('postUser: json', json)
                return json
            })
    }
    const putUser = (user) => {
        //console.log('putUser:', user)
        return bms.apiPut(`${bms.apiMap.users}/${user.id}`, JSON.stringify(user))
            .then( (json) => {
                //console.log('putUser: json', json)
                return json
            })
    }
    const queryUser = (user) => {
        //console.log('getUser:', user)
        return bms.apiGet(`${bms.apiMap.users}/${user.query}`)
            .then( (json) => {
                return json
            })
    }

    const addUser = (user) => {
        //console.log('addUser: user', user)
        dispatch({type: USER_ADD, user})
    }
    useEffect(() => {
        loadUsers().then((users) => {
            dispatch({type: USER_SET, users})
        })
            .catch( err => {
                console.log('UserContextProvider.mount: ERROR fetch users\n', err.message)
                if( err.response ) {
                    console.log('UserContextProvider.mount: ERROR fetch users\n', err.response.status, err.response.message)
                    if( err.response.status === 401 )
                        history.push('/login')
                }
            } )
    }, [loadUsers, history])

    // Manipulação de contexto e reducers
    state.dispatch  = dispatch
    state.addUser   = addUser
    state.loadUsers = loadUsers

    // Chamadas a API
    state.postUser  = postUser
    state.putUser   = putUser
    state.queryUser = queryUser

    return (
        <UserContext.Provider value={state}>
            {children}
        </UserContext.Provider>
    )

}

export default UserContextProvider

export const AUTH_LOGIN   = 'AUTH_LOGIN'
export const AUTH_LOGOUT  = 'AUTH_LOGOUT'

export const CHANGE_TAB   = 'CHANGE_TAB'
export const CONTEXT_LOADED = 'CONTEXT_LOADED'

export const initialState = {
    isLoggedIn: false,
    session: {},
    urlApi: "",
    activeTabKey: 1,
}

export const BMSReducer = (state, action) => {
    switch(action.type) {
        //case ETAPA_REMOVE:
        //    return state.filter(etapa => etapa.id !== action.id)
        case CHANGE_TAB:
            return {
                ...state,
                activeTabKey: action.activeTabKey
            }

        case CONTEXT_LOADED:
            return {
                ...state,
                isLoading: false
            }

        case AUTH_LOGIN:
            return {
                ...state,
                session: {
                    nome: action.nome,
                    id: action.id,
                    user_id: action.user_id,
                    role: action.role,
                },
                isLoggedIn: true
            }

        case AUTH_LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                isLoading: false,
            }

        default:
            return state;
    }
}


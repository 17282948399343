export const USER_ADD    = 'USER_ADD'
export const USER_REMOVE = 'USER_REMOVE'
export const USER_SAVEID = 'USER_SAVEID'
export const USER_SET    = 'USER_SET'

export const userReducer = (state, action) => {
    switch(action.type) {
        case USER_ADD:
            console.log('userReducer: USER_ADD:', action)
            return {...state,
                users: [...state.users, {
                    id: action.user.id,
                    username: action.user.username,
                    name: action.user.name,
                    email: action.user.email,
                    role: action.user.role,
                }]
            }

        case USER_REMOVE:
            return {...state,
                users: state.users.filter(user => user.id !== action.user.id)
            }

        //case USER_SAVEID:
        //    const toChange = state.filter(torneio => torneio.id === action.uuid)
        //    torneioReducer(state, {
        //        type: USER_REMOVE,
        //        id: toChange.id
        //    })
        //    toChange.id = action.id
        //
        //    return torneioReducer(state, {
        //        type: USER_ADD,
        //        torneio: toChange,
        //    })

        case USER_SET:
            return {...state,
                users: action.users
            }

        default:
            return state;
    }
}


export const JOBS_ADD     = 'JOBS_ADD'
export const JOBS_REMOVE  = 'JOBS_REMOVE'
export const JOBS_REPLACE = 'JOBS_REPLACE'
export const JOBS_SET     = 'JOB_SET'
export const JOBS_SELECT  = 'JOBS_SELECT'

//export const JOB_MESSAGE_ADD    = 'JOB_MESSAGE_ADD'
//export const JOB_MESSAGE_REMOVE = 'JOB_MESSAGE_REMOVE'
export const JOB_ACCOUNTS_SET   = 'JOB_ACCOUNTS_SET'
export const JOB_MESSAGES_SET   = 'JOB_MESSAGES_SET'
export const JOB_RECIPIENTS_SET = 'JOB_RECIPIENTS_SET'

export const emptyJob = {
    user_id: "",
    messages: [],
    recipients: [],
    accounts: [],
}

export const initialState = { jobs: [], selectedJob: null }

export const jobReducer = (state, action) => {
    //const job = (id) => {
    //    const x = state.jobs.filter(job => job.id === id)
    //    return x[0] ? x[0] : null
    //}

    switch(action.type) {
        case JOBS_ADD:
            //console.log('jobReducer: JOB_ADD: state:', state, 'action:', action)
            if( action.job )
                return {...state,
                    jobs: [...state.jobs, action.job ]
                }
            break

        case JOBS_REMOVE:
            // action = { type: JOBS_REMOVE,
            //            job: {id: "55xxx", ... } }
            //console.log('jobReducer: JOBS_REMOVE: state:', state, 'action:', action)
            if( action.job )
                return {...state,
                    jobs: state.jobs.filter(job => job.id !== action.job.id)
                }
            break

        case JOBS_REPLACE:
            // We need job ID to replace job
            // action = { type: JOBS_REPLACE,
            //            job: {id: "55xxx", ... } }
            //console.log('jobReducer: JOBS_REPLACE: state:', state, 'action:', action)
            if( action.job )
                return jobReducer(
                    jobReducer(state, {
                        type: JOBS_REMOVE,
                        job: action.job,
                    }), {
                        type: JOBS_ADD,
                        job: action.job,
                    })
            break

        case JOBS_SET:
            // action = { type: JOBS_REPLACE, jobs: [ {}, ... ] }
            //console.log('JOB_SET: state:', state, 'action:', action)
            if( action.jobs )
                return {...state,
                    jobs: action.jobs
                }
            break

        case JOBS_SELECT:
            // action = { type: JOB_ACCOUNTS_SET, id: "xxx",
            //            job: [ {id: "55xxx", }, ... ] }
            //console.log('JOBS_SELECT: state:', state, 'action:', action)
            if( action.job )
                return {...jobReducer( state, {
                    type: JOBS_REPLACE,
                    job: action.job,
                    }),
                    selectedJob: action.job,
                }
            return {...state,
                selectedJob: action.job,
            }

        case JOB_ACCOUNTS_SET: {
            // We need job ID to set messages properly
            // action = { type: JOB_ACCOUNTS_SET, id: "xxx",
            //            accounts: [ {id: "55xxx", }, ... ] }
            //console.log('jobReducer: JOB_ACCOUNTS_SET:', action)
            const job = state.jobs.filter(j => j.id !== action.id)

            // Call to JOBS_REMOVE followed by call to JOBS_ADD
            return jobReducer(
                jobReducer(state, {
                    type: JOBS_REMOVE,
                    id: action.id,
                }), {
                    type: JOBS_ADD,
                    job: {...job, accounts: action.accounts},
                },
            )
        }
        case JOB_MESSAGES_SET: {
            // We need job ID to set messages properly
            // action = { type: JOB_MESSAGES_SET, id: "xxx", messages: ["xxx", "yyy"] }
            //console.log('jobReducer: JOB_MESSAGES_SET: state:', state, 'action:', action)
            const job = state.jobs.filter(j => j.id === action.id)[0]
            //console.log('jobReducer: JOB_MESSAGES_SET: job,', job)

            if( ! job )
                return state

            // Call to JOBS_REMOVE followed by call to JOBS_ADD
            //const newJob = {...job, messages: action.messages}
            //console.log('jobReducer: JOB_MESSAGES_SET: newJob,', newJob)

            return jobReducer(
                jobReducer(state, {
                    type: JOBS_REMOVE,
                    id: action.id,
                }), {
                    type: JOBS_ADD,
                    job: {...job, messages: action.messages},
                },
            )
        }
        case JOB_RECIPIENTS_SET: {
            // We need job ID to set recipients properly
            // action = { type: JOB_RECIPIENTS_SET, id: "xxx",
            //            recipients: [ {phone: "55xxx", alias: "yyy", customField1: "zzz", }, ... ] }
            //console.log('jobReducer: JOB_MESSAGES_SET:', action)
            const job = state.jobs.filter(j => j.id === action.id)

            // Call to JOBS_REMOVE followed by call to JOBS_ADD
            return jobReducer(
                jobReducer(state, {
                    type: JOBS_REMOVE,
                    id: action.id,
                }), {
                    type: JOBS_ADD,
                    job: {...job, recipients: action.recipients},
                },
            )
        }
        default:
            return state
    }

    return state
}


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import BMSContextProvider from './contexts/BMSContext'

ReactDOM.render(
    <BMSContextProvider>
        <App />
    </BMSContextProvider>,
    document.getElementById('app')
);


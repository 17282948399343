import React, {useContext, useEffect, useRef, useState} from 'react'
import {BMSContext} from '../../contexts/BMSContext'

import './login.css'
import {Redirect} from 'react-router-dom'
import {Alert, Button} from 'rsuite'

const LoginPage = () => {
    const bms = useContext(BMSContext)
    const [loginState, setLoginState] = useState({
        login: '',
        passwd: '',
        validForm: false,
    })

    const onChangeLogin   = (e) => { setLoginState( {...loginState, login: e.target.value,
        validForm:  e.target.value.length  > 0 && loginState.passwd.length > 0
    })  }
    const onChangePasswd  = (e) => { setLoginState( {...loginState, passwd: e.target.value,
        validForm: loginState.login.length  > 0 &&  e.target.value.length > 0
    }) }
    const handleKeyDown   = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e)
        }
    }
    const handleSubmit    = (e) => {
        setLoginState( {...loginState, validForm: false })
        bms.Login(loginState.login, loginState.passwd)
            .then( (session) => {
                console.log(session)
            },   (err) => {
                console.log('handleSubmit:', err)
                setLoginState( {...loginState, validForm: true })
                loginField.current.focus()
                Alert.error(err.message)
            })

    }

    const loginField    = useRef(null)

    useEffect(() => {
        if(loginField && loginField.current)
            loginField.current.focus()
    }, [])

    const panel2Style = {
        color: 'rgb(66,85,93)',
        marginTop: '40px',
    }

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    }

    const fieldStyle = {
        position: 'relative',
        userSelect: 'none',
        color: 'rgb(134,134,134)',
        fontSize:   '14px',
        lineHeight: '24px',
        fontWeight:  400,
        width: '100%',
        borderRadius: '4px',
        borderWidth: '1px',
        marginBottom: '30px',

        boxShadow: 'rgb(179,179,179) 0px 0px 0px 1px',
    }
    const field1Style = {
        ...fieldStyle,
    }
    const field2Style = {
        ...fieldStyle,
        display: 'block',

    }

    const labelStyle = {
        position: 'absolute',
        left: 'calc(8px)',
        backgroundColor: 'rgb(255,255,255)',
        borderWidth: '1px',
        padding: '0px 8px',

        top: '0px',
        transform: 'translateY(-50%)',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',

        transition: 'all 300ms ease-in-out 0s',
    }
    const inputStyle = {
        fontFamily: 'Arial',
        fontSize: '13px',
        fontStyle: 'normal',
        margin: 0,
        textTransform: 'none',
        textShadow: 'none',
        textIndent: 0,
        display: 'inline-block',

        boxSizing: 'border-box',
        outline: 0,
        padding: '12px 16px',
        width: '100%',

        transition: 'all 300ms ease-in-out 0s',
    }

    if( bms.isLoggedIn )
        return <Redirect to={'/'} />

    return (
        <div className={'modal loginBox'}>
            <div className={'titlePanel'}>
                <div className={'title'}>
                    <p className={'titulo1'}>Login</p>
                    <p className={'titulo2'}>Acesso ao BMS</p>
                </div>
            </div>
            <div style={panel2Style}>
                <form style={formStyle}>
                    <div style={field1Style}>
                        <label htmlFor={'input-login'} style={labelStyle}>Usuário</label>
                        <input id={'input-login'} type={'text'}
                               onChange={e => onChangeLogin(e)}
                               onKeyDown={e => handleKeyDown(e)}
                               name={'login'} value={loginState.login} style={inputStyle} ref={loginField} />
                    </div>
                    <div style={field2Style}>
                        <label htmlFor={'input-passwd'} style={labelStyle}>Senha</label>
                        <input id={'input-passwd'} type={'password'}
                               onChange={e => onChangePasswd(e)}
                               onKeyDown={e => handleKeyDown(e)}
                               name={'passwd'} value={loginState.passwd} style={inputStyle} />

                    </div>
                    <Button disabled={! loginState.validForm} appearance={'primary'}
                            onClick={e => handleSubmit(e)}>Acessar</Button>
                </form>
            </div>
        </div>
    )
}

export default LoginPage
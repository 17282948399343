import React, { Suspense, useContext } from 'react'
import { HashRouter as Router, Switch, Redirect, Route } from 'react-router-dom'

import 'rsuite/dist/styles/rsuite-default.css'
import LoginPage from './layouts/login/LoginPage'
import DashboardPage from './layouts/dashboard/DashboardPage'
import { BMSContext } from './contexts/BMSContext'

import './style.scss'

//class DebugRouter extends Router {
//    constructor(props){
//        super(props);
//        console.log('initial history is: ', JSON.stringify(this.history, null,2))
//        this.history.listen((location, action)=>{
//            console.log(
//                `The current URL is ${location.pathname}${location.search}${location.hash}`
//            )
//            console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
//        });
//    }
//}

class AuthRequiredRoute extends Route {
    render() {
        if(this.props.state.isLoading){
            return (<div>Carregando...</div>)
        }

        if(!this.props.state.isLoggedIn){
            return <Redirect to="/login" />
        }

        return <div>{this.props.children}</div>
    }
}
const App = () => {
    const bms = useContext(BMSContext)

    return (
        <Router>
            <Suspense fallback={<div>Carregando...</div>}>
                <Switch>
                    <Route exact path="/login"><LoginPage/></Route>
                    <AuthRequiredRoute state={bms}><DashboardPage/></AuthRequiredRoute>
                </Switch>
            </Suspense>
        </Router>
    )
}

export default App;

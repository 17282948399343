import React, { createContext, useEffect, useReducer } from 'react'
import { initialState, BMSReducer, AUTH_LOGIN, AUTH_LOGOUT, CONTEXT_LOADED } from '../reducers/BMSReducer'
import { useHistory } from 'react-router-dom'
import ApiError from '../components/ApiError'

const storageKey = `bmsapp`
const api = () => {
    return { urlApi: process.env.NODE_ENV === "development" ? "http://127.0.0.1:4000/api"
            : "https://bms.brate.com.br/api"
    }
}
const apiMap     = {
    'accounts':   `${api().urlApi}/accounts`,
    'jobs':       `${api().urlApi}/jobs`,
    'session':    `${api().urlApi}/users/session`,
    'users':      `${api().urlApi}/users`,
    'whatsapp':   `${api().urlApi}/whatsapp/auth`,  // '/check/{account_id}' e '/qrcode/{account_id}'
}

export const BMSContext = createContext()

const BMSContextProvider = (props) => {
    const history = useHistory();
    const hPush = history ? history.push : null
    //const localAdmin = localStorage.getItem(storageKey)
    const abortController = new AbortController()

    const [state, dispatch] = useReducer(BMSReducer, initialState, () => {
        const bmsapp = localStorage.getItem(storageKey)
        const nctx = {
            ...initialState,
            urlApi: api().urlApi,
            isLoading: true,
        }
        if( bmsapp ) { nctx.session = JSON.parse(bmsapp) }

        return nctx
    })

    const apiHeaders = () => ({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.session.id}`,
    })
    const fetchInitCorsBeares = (method) => ({
        method: method,
        mode: 'cors',
        headers: apiHeaders(),
    })

    const login = (u, p) => {
        const url = `${state.urlApi}/login`
        const json = JSON.stringify({ username: u, passwd: p })

        return fetch(url, {
            method: 'POST', mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: json,
            signal: abortController.signal,
        })
            .then( (response) => {
                if( response.status !== 200 )
                     throw new ApiError( response.statusText, response )

                return response.json()
            })
            .then( (json) => {
                //console.log('BMSContextProvider.login: json POST', json)
                dispatch({
                    type: AUTH_LOGIN,
                    id: json.data.session.id,
                    user_id: json.data.session.user_id,
                    nome: json.data.session.nome,
                    role: json.data.session.role,
                })
                const session = {
                    id: json.data.session.id,
                    user_id: json.data.session.user_id,
                    nome: json.data.session.nome,
                }
                localStorage.setItem(storageKey, JSON.stringify(session))
                if( hPush ) hPush('/')
                window.location.href = '/'

                return json.data.session
            })
            //.catch( err => console.log('BMSContextProvider.login: ERROR fetch post login:', err) );
    }
    const logout = () => {
        //console.log('bmsApi: logout: logging out')
        dispatch({ type: AUTH_LOGOUT, })
    }

    const bmsApi  = (url, requestInit) => {
        return fetch(url, requestInit)
            .then( (response) => {
                switch(response.status) {
                    case 401:
                        logout()
                        return JSON.stringify({ error: {
                                name: 'AuthorizationError',
                                message: response.statusText,
                                response,
                            } })
                    case 405:
                    case 422:
                    case 424:
                        console.log('bmsApi:', response)
                        return JSON.stringify({ error: {
                                name: 'APIError',
                                message: response.statusText,
                                response,
                            } })
                    case 200:
                    case 201:
                    case 204:
                        return response.json()
                    default:
                        throw JSON.stringify({
                            name: 'UnknownError',
                            message: response.statusText,
                            response,
                        })
                }
            })
    }
    const apiGet  = (url, signal = null) => {
        return bmsApi(url, { ...fetchInitCorsBeares('GET'),
            signal: signal,
        } )
    }
    const apiPost = (url, body = '', signal = null) => {
        return bmsApi(url, { ...fetchInitCorsBeares('POST'),
            body: body, signal: signal,
        } )
    }
    const apiPut = (url, body = '', signal = null) => {
        return bmsApi(url, { ...fetchInitCorsBeares('PUT'),
            body: body, signal: signal,
        } )
    }

    const getSessionDetails = (id) => {
        //const url = apiMap.session
        const json = JSON.stringify({ id: id })

        return ( apiPost( apiMap.session, json, abortController.signal )
            .then( (json) => {
                dispatch({ type: CONTEXT_LOADED, })
                if( json && json.data ) {
                    dispatch({
                        type: AUTH_LOGIN,
                        id: json.data.session.id,
                        user_id: json.data.session.user_id,
                        nome: json.data.session.nome,
                        role: json.data.session.role,
                    })
                }

                return json
            })
        );
    }

    useEffect(() => {
        if( !state.session || !state.session?.id )
            logout()
        else {
            if( state.isLoading && !state.isLoggedIn && state.session.id ) {
                getSessionDetails( state.session.id ).catch( (err) => {
                    console.log('useEffect: getSessionDetails: err', err)
                    //console.log('state:', state)
                })
            }
        }

        return function cleanup() {
            abortController.abort()
        }
    }, [state.isLoading, state.isLoggedIn, state.session, state.session.id, ])          // eslint-disable-line
    useEffect(() => {
        if( !state.isLoggedIn ) {
            hPush && hPush('/login')
        }

        return function cleanup() {
            abortController.abort()
        }
    }, [state.isLoggedIn, hPush, abortController])

    state.apiMap     = apiMap
    state.apiHeaders = apiHeaders
    state.apiGet     = apiGet
    state.apiPost    = apiPost
    state.apiPut     = apiPut
    state.storageKey = storageKey

    //state.fetchInitCorsBeares = fetchInitCorsBeares

    state.dispatch = dispatch
    state.Login  = login
    state.Logout = logout

    return (
        <BMSContext.Provider value={state}>
            {props.children}
        </BMSContext.Provider>
    )
}

export default BMSContextProvider
